<template>
  <div class="select-esl-type">
    <el-dialog
      class="tag-dialog"
      :visible.sync="tagDialog"
      :close-on-click-modal="false"
       width="700px"
       :title="$t('systemParameters.ESLTypeSelect')"
    >
    <div style="">
      <el-scrollbar style="height: 100%">
        <div class="esl-type-list">
          <el-checkbox-group v-model="checkedList">
            <el-checkbox 
              v-for="(itemTag,index) in itemCheckList" 
              :key="itemTag.tagTypeCodeId" 
              :label="index"
            >{{ itemTag.typeName }}</el-checkbox>
          </el-checkbox-group>
        </div>
      </el-scrollbar>
      <div slot="footer" class="dialog-footer">
        <el-button @click="tagDialog = false" style="background: #D4D4D4;">{{$t('button.Cancel')}}</el-button>
        <el-button type="success" class="base-color-btn" @click="handleConfirm()" >{{$t('button.Save')}}</el-button>
      </div>
    </div>
      
    </el-dialog>
  </div>
</template>
<script>
  import { getAllTagType } from '@/libs/api/system-parameters'
  export default {
    name:'selectEslType',
    components: {
    },
    props: {
      // merchantId: {
      //   type: String,
      //   default: 'default',
      // },
    },
    data() {
      return {
        tagDialog:false,
        checkedList: [], //选中的数组
        itemCheckList: [], 
      }
    },
    created(){
      
    },
    mounted(){
      
    },
    watch: {
    },
    methods: {
      open(){
        this.tagDialog = true
        this.getData()
      },
      // 获取价签尺寸列表
      async getData() {
        this.tagDialog = true
        try {
          let userData = JSON.parse(localStorage.getItem('userData'))
          const res = await getAllTagType({merchantId: userData.merchantId})
          console.log('2222res========', res)
          if (res.status === 200 && res?.data.code === 1) {
            // this.checkedList = []
            this.itemCheckList = res?.data?.data
            this.itemCheckList.forEach(item => {
              if (item.selected) {
                this.checkedList.push(item.tagTypeCodeId)
              }
            })
            console.log('checkedList值========', this.checkedList)
          } else {
            this.$message.error(res.data.message)
          }
        } catch (error) {
          this.$message.error(error.message)
        }
        
      },
      // 点击确定
      handleConfirm(){
        // let selectedTags = this.checkedList.join(',')
        let selectedArrObj= []
        let checkedList = this.checkedList
        let checkList = this.itemCheckList
        checkedList.forEach(item => {
          selectedArrObj.push(checkList[item])
        })
        let selectedTags = selectedArrObj.map(item => item.tagTypeCodeId).join(',')
        this.tagDialog = false
        this.$emit('confirmSelect', selectedTags, selectedArrObj)
      },

    }
  }
</script>
<style lang="scss" scoped>
  .select-esl-type{
    .esl-type-list{
      .el-checkbox-group{
        width: 200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
    }
    // 重置样式
    ::v-deep .el-checkbox{
      display: block;
      width: 50%;
      padding: 20px 10px;
      box-sizing: border-box;
      margin: 0;
      text-align: left;
    }
    .dialog-footer{
      padding: 20px 0;
      text-align: center;
      ::v-deep .el-button{
        width: 120px;
      }
    }
  }
</style>