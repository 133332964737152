<template>
  <div class="systemManage-create create-customer">
    <el-dialog 
      v-model="dialogVisible" 
      :title="formData.customerId ? $t('customer.Customer Details') : $t('customer.Create Customer')" 
      :before-close="handleClose"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="700px"
    >
      <div class="dialog-common-container">
        <el-scrollbar style="height:100%">
          <div class="form-box">
            <el-form 
              label-width="140px"
              style="width: 100%"
              :model="formData"
              ref="form" 
              :rules="rules"
              size="small"
            >
              <div class="section-tit"><div class="flag"></div>{{$t('customer.Basic Information')}}</div>
              <el-form-item :label="$t('customer.Superior')">
                <el-input v-model="formData.superior" disabled/>
              </el-form-item>
              <el-form-item :label="$t('customer.Customer Type')" prop="customerType">
                <el-select v-model="formData.customerType" placeholder="" size="small" :disabled="formData.customerId !== ''">
                  <el-option
                    v-for="item in typeList"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('customer.Customer Code')"  prop="customerCode">
                <el-input 
                  v-model="formData.customerCode" 
                  placeholder=""
                  clearable 
                />
              </el-form-item>
              <el-form-item  :label="$t('customer.Customer Name')" prop="customerName">
                <el-input 
                  v-model="formData.customerName" 
                  placeholder=""
                  clearable 
                />
              </el-form-item>
              <el-form-item :label="$t('customer.Status')" >
                <el-switch v-model="formData.status" />
              </el-form-item>
              <el-form-item :label="$t('customer.Search Key')" prop="searchKey">
                <el-input 
                  v-model="formData.searchKey" 
                  placeholder=""
                />
              </el-form-item>
              
              <template v-if="!formData.customerId">
                <div class="section-tit"><div class="flag"></div>{{$t('customer.Account Information')}}</div>
                <el-form-item :label="$t('customer.User Name')" prop="userName">
                  <el-input 
                    v-model="formData.userName" 
                    placeholder=""
                  >
                  </el-input>
                </el-form-item>
                <el-form-item :label="$t('customer.User Account')" prop="account">
                  <el-input 
                    v-model="formData.account" 
                    placeholder=""
                  >
                  </el-input>
                </el-form-item>
                <el-form-item :label="$t('customer.Password')" prop="password">
                  <el-input
                    v-model="formData.password"
                    type="password"
                    placeholder=""
                    show-password
                  >
                  </el-input>
                </el-form-item>
                <el-form-item :label="$t('customer.Confirm Password')" prop="confirmPassword">
                  <el-input
                    v-model="formData.confirmPassword"
                    type="password"
                    placeholder=""
                    show-password
                  >
                    <!-- <template #prefix>
                      <span><i class="el-icon-lock"></i></span>
                    </template> -->
                  </el-input>
                </el-form-item>
              </template>

              <template v-if="!formData.customerId">
                <div class="section-tit"><div class="flag"></div>{{$t('customer.Other')}}</div>
                <el-form-item :label="$t('customer.Language')" prop="languageId">
                  <el-select v-model="formData.languageId"  placeholder="">
                    <el-option
                      v-for="item in languageList"
                      :key="item.languageId"
                      :label="item.name"
                      :value="item.languageId"
                    />
                  </el-select>
                </el-form-item>
                <template v-if="formData.customerType === '1503'">
                  <el-form-item :label="$t('customer.ESL Type')"  prop="tagTypeIds">
                    <el-input 
                      v-model="formData.tagTypeNames" 
                      placeholder=""
                      @focus="onFocus"
                      readonly
                    >
                      <template #suffix>
                        <span style="cursor: pointer;padding: 0 10px;" @click="onFocus"><i class="el-icon-more"></i></span>
                      </template>
                    </el-input>
                  </el-form-item>
                </template>
              </template>
              

              <!-- 保存按钮 -->
              <div class="form-btn-section">
                <el-button color="#28C76F" @click="handleClose">{{$t('button.Cancel')}}</el-button>
                <el-button color="#28C76F" type="success" @click="onSubmit">{{$t('button.Save')}}</el-button>
              </div>

            </el-form>
          </div>
        </el-scrollbar>
      </div>
    </el-dialog>

    <!-- 选择价签 -->
    <selectEslType ref="selectEslTypeRef" @confirmSelect="handleConfirmEsl"></selectEslType>
  </div>
</template>
<script>
  import { trim } from 'lodash';
  import { customerCreate, customerUpdate, languageGetList } from "@/libs/api/system-management-customer";
  import { checkKeyRegex, checkIsEmail, checkIsPasswordRegex } from "@/utils/common";
  import selectEslType from '@/components/select-esl-type/index'
  import md5 from 'js-md5'
  let loading;

  export default {
    name:'createCustomer',
    components: {
      selectEslType
    },
    props: {
      typeList: {
        type: Array
      },
      editData:{
        type:Object
      }
    },
    data() {
      return {
        userData:JSON.parse(localStorage.getItem('userData')),
        dialogVisible:false,
        languageList:[],
        initFormData:{
          customerId:'',
          superior: '', // 当前用户的 customerName
          customerType:'', //客户类型(1501: 运维 1502: 经销商 1503 :商户总店)
          customerCode:'', //客户编码
          customerName:'', //客户名称
          status:true,   // 状态(1401:启用 1402:禁用)
          searchKey:'', // searchKey
          userName:'', //用户名称
          account:'', //账号
          password:'', //用户密码
          confirmPassword:'', 
          languageId: '', //默认语言ID
          tagTypeNames:'',
          tagTypeIds:'' //价签类型ID，多个以,隔开，创建商户总店时可以传
        },
        formData:{
          customerId:'',
          superior:'', // 当前用户的 customerName
          customerType:'', //客户类型(1501: 运维 1502: 经销商 1503 :商户总店)
          customerCode:'', //客户编码
          customerName:'', //客户名称
          status:true,   // 状态(1401:启用 1402:禁用)
          searchKey:'', // searchKey
          userName:'', //用户名称
          account:'', //账号
          password:'', //用户密码
          confirmPassword:'', 
          languageId: '', //默认语言ID 
          tagTypeNames:'',
          tagTypeIds:'' //价签类型ID，多个以,隔开，创建商户总店时可以传  --未做
        },
        rules:{
          customerType:[
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' },
          ],
          customerCode: [
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' },
            { max: 100, message: this.$t('formValidate.maxLength'), trigger: 'blur'},
            // { validator: this.checkCode, trigger: 'blur' }
          ],
          customerName:[
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' },
            { max: 100, message: this.$t('formValidate.maxLength'), trigger: 'blur'}
          ],
          searchKey:[
            { max: 100, message: this.$t('formValidate.maxLength'), trigger: 'blur'}
          ],
          userName:[
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' },
            { max: 100, message: this.$t('formValidate.maxLength'), trigger: 'blur'}
          ],
          account:[
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' },
            { max: 100, message: this.$t('formValidate.maxLength'), trigger: 'blur'},
            { validator: this.checkEmail, trigger: 'blur' }
          ],
          password:[
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' },
            { validator: this.checkPassword, trigger: 'blur' }
          ],
          confirmPassword:[
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' },
            { validator: this.checkPasswordConfirm, trigger: 'blur' }
          ]
        },
        userData:JSON.parse(localStorage.getItem('userData')),
      }
    },
    created(){
      
    },
    mounted(){
      
    },
    watch: {
      editData: function(val) {
        console.log('--watch--editData',val)
        if(val && val.customerId){
          let data ={
            ...val,
            status:val.status ===1401 ? true : false,
            customerType:val.translateCustomerType,
            password:'Test123456', //编辑时，会校验改字段，但是该字段没有获取值，该值仅限用户检验表单通过
            confirmPassword:'Test123456' ////编辑时，会校验改字段，但是该字段没有获取值，该值仅限用户检验表单通过

          }
          this.formData = data
        } else {
          let userData = JSON.parse(localStorage.getItem('userData'))
          let obj = {
            ...this.initFormData,
            superior:userData.customerName || userData.userName
          }
          this.formData = obj
        }
        
      }
    },
    methods: {
      // 打开弹窗
      open(){
        this.dialogVisible = true
        this.getLanguageList()
      },
      // 获取语言列表---旧
      async getLanguageListOld () {
        let localData  = JSON.parse(localStorage.getItem("languageList"));
        if (localData && localData.languageList && localData.languageList.length > 0){
          // 从缓存获取数据
          this.languageList = localData.languageList;
        } else {
          // 服务端获取数据
          let { data } = await languageGetList();
          console.log('获取语言数据',data);
          if(data.state){
            let list = data.data;
            list = list.map((value) => ({
              ...value,
              text:""
            }))
            localStorage.setItem("languageList", JSON.stringify({languageList: list}));
            this.languageList = list;

          } else {
            this.$message.error(data.message)
          }
        } 
      },
      // 获取语言列表---新
      async getLanguageList () {
        try {
          let { data } = await languageGetList();
          console.log('获取语言数据',data);
          if(data.state){
            let list = data.data;
            list = list.map((value) => ({
              ...value,
              text:""
            }))
            localStorage.setItem("languageList", JSON.stringify({languageList: list}));
            this.languageList = list;
          } else {
            this.$message.error(data.message)
          }
        } catch (error) {
          this.$message.error(error.message)
        }
        
      },
      // 关闭弹窗
      handleClose(){
        this.$refs.form.resetFields()
        this.dialogVisible = false
      },
      // 校验 --- code
      checkCode(rule,value,callback){
        console.log('code校验value,rule',value,rule,callback)
        if (!value) {
          return callback(new Error(this.$t('formValidate.required fields')))
        } else {
          let isKey = checkKeyRegex(value)
          if(!isKey){
            return callback(new Error('Please enter the correct Customer Code'))
          }else {
            callback()
          }
        }
      },
      // 校验 --- 校验邮箱
      checkEmail(rule,value,callback){
        console.log('邮箱value,rule',value,rule,callback)
        if (!value) {
          return callback(new Error(this.$t('formValidate.required fields')))
        } else {
          let isEmail = checkIsEmail(value)
          if(!isEmail){
            return callback(new Error(this.$t('login.FormError1')))
          }else {
            callback()
          }
        }
      },
      // 校验 --- 密码
      checkPassword(rule,value,callback){
        console.log('新密码密码',value,rule)
        if (!value) {
          return callback(new Error(this.$t('formValidate.required fields')))
        } else {
          let res = checkIsPasswordRegex(value)
          if(res){
            if (this.formData.confirmPassword !== '') {
              this.$refs.form.validateField('confirmPassword');
            }
            callback()
            // if(!this.formData.confirmPassword) return
            // if(value === this.formData.confirmPassword){
            //   callback()
            // } else {
            //   return callback(new Error('两次密码输入不一致！'))
            // }
          } else {
            return callback(new Error(this.$t('profile.FormError1')))
          }
        }
      },
      // 校验 --- 新密码确认
      checkConfirm(rule,value,callback){
        console.log('新密码--确认',value,rule)
        if (!value) {
          return callback(new Error('Please input password'))
        } else {
          if(value === this.formData.password){
            callback()
          } else {
            return callback(new Error(this.$t('profile.FormError2')))
          }
        }
      },
      // 选择价签类型
      onFocus(){
        this.$refs.selectEslTypeRef.open()
      },
      // 选择价签类型--确定
      handleConfirmEsl(val,arr){
        console.log('选择价签---确定',val)
        let name = arr.map(item => item.typeName).join(',  ')
        this.formData.tagTypeNames = name
        this.formData.tagTypeIds = val
      },
      // 表单提交
      onSubmit(){
        loading = this.$loading({
          text:"loading...",
          lock: true,
          background: 'rgba(0, 0, 0, 0.7)',
        })
        this.$refs.form.validate( valid => {
          if (valid) {
            console.log('表单校验通过',this.formData)
            if(this.formData.customerId){
              this.customerUpdate()
            } else {
              this.customerCreate()
            }
          } else {
            console.log('未通过')
            loading.close()
            console.log('error submit!!');
            return false
          }
        })
      },
      // 请求--- 创建客户
      async customerCreate(){
        let {customerType,status,customerCode,customerName,searchKey,account,userName, password, languageId, tagTypeIds} = this.formData
        console.log({status})
        let params={
          customerType,
          status:status ===true ? 1401:1402,
          customerCode:trim(customerCode),
          customerName:trim(customerName),
          searchKey:trim(searchKey),
          account:trim(account),
          userName:trim(userName),
          password:md5(password),
          languageId,
          tagTypeIds
        }
        try {
          const res = await customerCreate(params)
          let response = res.data
          if(response.state){
            this.$message({
              message:this.$t('commonMessage.CreateSucess'),
              type:'success',
            });
            this.dialogVisible = false
            this.$emit('getData')
            loading.close()
          } else{
            loading.close()
            this.$message.error(response.message)
          }
        } catch (error) {
          this.$message.error(error.message)
        }
      },
      // 请求--- 客户修改
      async customerUpdate(){
        let {customerId,status,customerCode,customerName,searchKey,languageId,tagTypeIds} = this.formData
        let params={
          customerId,
          status:status === true ? 1401:1402,
          customerCode:trim(customerCode),
          customerName:trim(customerName),
          searchKey:trim(searchKey),
          languageId,
          tagTypeIds
        }
        try {
          const res = await customerUpdate(params)
          let response = res.data
          if(response.state){
            this.$message({
              message:this.$t('commonMessage.ModifySucess'),
              type:'success',
            });
            this.dialogVisible = false
            this.$emit('getData')
            loading.close()
          } else{
            loading.close()
            this.$message.error(response.message)
          }
        } catch (error) {
          this.$message.error(error.message)
        }
        
      }
    }
  }
</script>
<style lang="scss" scoped>
  .systemManage-create{
    .dialog-common-container{
      // min-height: 600px;
      height: calc(70vh);
      ::v-deep .el-scrollbar__wrap{
        overflow-x: hidden;
        padding-bottom: 40px;
      }
    }
    .form-box{
      width: 450px;
      margin: 0 auto;
    }
    .section-tit{
      position: relative;
      font-weight: 600;
      padding: 10px 10px 10px 20px;
      background: whitesmoke;
      margin-bottom: 20px;
      border-radius: 4px;
      .flag{
        position: absolute;
        width: 4px;
        height: 18px;
        border-radius: 2px;
        background: #35B871;
        top: 0px;
        bottom: 0px;
        left: 10px;
        margin: auto;
      }
    }
    .form-btn-section{
      width: 100%;
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      ::v-deep .el-button{
        width: 110px;
      }
    }
    // 重置样式
    // 重置样式
    ::v-deep .el-dialog{
      margin-top: 5vh !important;
    }
    .el-select{
      width: 100%;
    }
    .el-button--success{
      width: 100%;
    }
  }
</style>