import router from "../axios";

import config from "../../config/index"
const { baseUrl } = config;

// 获取客户列表
function getListCustomer(data) {
  return router({
    url: baseUrl+"customer/v1/getList",
    data:data,
    method: "POST",
  });
}

// 获取客户类型列表
function getCustomerTypeList(data) {
  return router({
    url: baseUrl+"customer/v1/getCustomerTypeList",
    data:data,
    method: "POST",
  });
}

// 获取角色详情  
function getCustomer(data) {
  return router({
    url: baseUrl+"customer/v1/get",
    data:data,
    method: "POST",
  });
}

// 获取语言列表
function languageGetList() {
  return router({
    url: baseUrl+"language/v1/getList",
    method: "POST",
  });
}
// 获取客户状态
function getCodeList(data) {
  return router({
    url: baseUrl+"code/v1/getList",
    data:data,
    method: "POST",
  });
}
// 获取帮助中心列表
function getList(data) {
  return router({
    url: baseUrl+"help/v1/getList",
    data:data,
    method: "POST",
  });
}
// 客户创建 
function customerCreate(data) {
  return router({
    url: baseUrl+"customer/v1/create",
    data:data,
    method: "POST",
  });
}

// 客户修改
function customerUpdate(data) {
  return router({
    url: baseUrl+"customer/v1/update",
    data:data,
    method: "POST",
  });
}

// 客户删除
function customerDelete(data) {
  return router({
    url: baseUrl+"customer/v1/delete",
    data:data,
    method: "POST",
  });
}



export {
  getListCustomer,
  getCustomerTypeList,
  getCustomer,
  languageGetList,
  customerCreate,
  customerUpdate,
  customerDelete,
  getCodeList,
  getList
}