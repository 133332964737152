<template>
  <div class="common-table-list systemManage-customer">
    <div class="location">
      <router-link style="color: #6e6b7b" to="/">{{
        $t("Welcome")
      }}</router-link> 
      <span> > {{ $t('System Management')}} > </span>
      <span style="color:#3461FF">{{ $t('Customer') }}</span>
    </div>
    <div class="hd card">
      <div class="handle-button-section">
        <el-button type="primary" size="small" @click="handleAdd">
          <i class="el-icon-plus" />
          {{$t('button.New')}}
        </el-button>
        <!-- <el-button type="danger" size="small" @click="handleBatchDel" :disabled="!selection()">
          <i class="el-icon-delete" />
          Batch Delete
        </el-button> -->
      </div>
    </div>
    <div class="bd card">
      <el-table
        v-loading="loading"
        ref="treeTable"
        :data="tableData"
        style="width: 100%;margin-bottom: 20px;"
        @selection-change="handleSelectionChange"
        @select-all="handleSelectAll"
        @select="handleRowSelect"
        :cell-class-name="cellClassName"
        row-key="id"
        lazy
        :load="loadNextLevel"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        class="layout-list-table"
        table-layout="fixed"
      >
        <el-table-column  :label="$t('customer.Customer Name')" min-width="180" fixed>
          <el-table-column  prop="customerName" width="180">
            <template slot="header" slot-scope="scope">
              <el-input v-model="searchParams.customerName" @input="getData" size="mini" placeholder="" clearable />
            </template>
            <template slot="default" slot-scope="{row}">
              <span v-if="!row.hasChildren" style="width:23px;height:10px;display: inline-block;"></span>
              <span>{{row.customerName}}</span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column  :label="$t('customer.Customer Code')" min-width="180" header-align="center">
          <el-table-column  prop="customerCode" min-width="180" align="center">
            <template slot="header" slot-scope="scope">
              <el-input v-model="searchParams.customerCode" @input="getData" size="mini" placeholder="" clearable />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('customer.Customer Type')"  min-width="150" header-align="center">
          <el-table-column  prop="translateCustomerType" min-width="150" align="center">
            <template slot="header" slot-scope="scope">
              <el-select v-model="searchParams.customerType" placeholder="" size="mini"  @change="getData" clearable>
                <el-option
                  v-for="item in typeList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column  :label="$t('customer.Status')" min-width="150" header-align="center">
          <el-table-column prop="translateStatus" min-width="150" align="center">
            <template #header>
              <el-select v-model="searchParams.status" placeholder="" size="mini"  @change="getData" clearable>
                <el-option
                  v-for="item in statusList"
                  :key="item.codeId"
                  :label="item.codeName"
                  :value="item.codeId"
                />
              </el-select>
              <!-- <el-select v-model="searchParams.status" placeholder="" size="small" @change="getData" clearable >
                <el-option label="启用" value="1401" />
                <el-option label="禁用" value="1402" />
              </el-select> -->
            </template>
            <template #default="scope">
              <div :class="`status-${scope.row.status} status`">{{scope.row.translateStatus}}</div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('customer.Search Key')" min-width="180" header-align="center">
          <el-table-column  prop="searchKey" width="200"  align="center">
            <template slot="header" slot-scope="scope">
              <el-input v-model="searchParams.searchKey" @input="getData" size="mini" placeholder="" clearable />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('customer.Create Time')" prop="createTime"  min-width="230" header-align="center">
          <el-table-column prop="createTime" width="230" align="center">
            <template slot="header" slot-scope="scope">
              <el-date-picker
                v-model="searchParams.date"
                type="daterange"
                start-placeholder=""
                end-placeholder=""
                size="mini"
                @change="handleDateChange"
                placement="bottom-start"
                value-format="yyyy-MM-dd"
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column fixed="right" :label="$t('customer.Action')" width="120" header-align="center">
          <el-table-column  align="center" width="120" >
            <template slot="header" ></template>
            <template slot="default" slot-scope="{row}">
              <div class="operate">
                <!-- 删除 -->
                <template v-if="!row.hasChildren">
                  <span class="table-icon"  @click="handleDelete(row)">
                    <i class="el-icon-delete"></i>
                  </span>
                </template>
                <span class="table-icon"  @click="handleEdit(row)">
                  <i class="el-icon-edit-outline"></i>
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </div>
    
    <!-- 创建用户 -->
    <createCustomer 
      ref="createCustomer" 
      :typeList="typeList"
      :editData="editData" 
      @getData="updateData">
    </createCustomer>

    <!-- 删除弹窗确认 -->
    <deleteConfirmDialog ref="deleteConfirmDialog" @onDeleteConfirm="customerDelete"></deleteConfirmDialog>

  </div>
</template>
<script>
  import { getListCustomer, getCustomerTypeList, getCustomer, getCodeList, customerDelete } from "@/libs/api/system-management-customer";
  import createCustomer from './components/createCustomer'
  import deleteConfirmDialog from '@/components/delete-confirm-dialog/index'

  export default {
    name:'systemManageCustomer',
    components: {
      createCustomer,
      deleteConfirmDialog
    },
    props: {},
    data() {
      return {
        tableData:[],
        typeList:[],
        statusList:[],
        loading:false,
        searchParams:{
          customerName:'',
          customerCode:'',
          customerType:'', //客户类型(1501: 运维 1502: 经销商 1503 :商户总店)
          status:'', //状态(1401:启用 1402:禁用)
          searchKey:'',
          startDate:'',
          endDate:''

        },
        deleteData:{},
        editData:{},
        userData:{}
      }
    },
    created(){
      this.initData()
    },
    mounted(){
      
    },
    methods: {
      initData(){
        this.getData()
        this.getCustomerTypeList()
        this.getCodeList()
      },
      // 获取数据
      async getData(){
        let {customerName,customerCode,customerType,status,searchKey,startDate,endDate} = this.searchParams
        console.log({status})
        console.log({startDate})
        let params = {
          customerName,
          customerCode,
          customerType: parseInt(customerType) || '',
          status:parseInt(status) || '',
          searchKey,
          startDate,
          endDate,
          customerId:''
        }
        try {
          const res = await getListCustomer(params)
          let response = res.data
          console.log('获取客户列表',response)
          if(response.state){
            let dataList = response.data.children
            dataList.forEach(element=> {
              element.id = element.customerId
              if(element.hasChildren === false) {
                // delete element['hasChildren']
                delete element['children']
              }
            })
            console.log('处理后的数据列表',dataList,this.$refs.treeTable)
            this.tableData = dataList   
          } else{
            this.$message.error(response.message)
          }
        } catch (error) {
          this.$message.error(error.message)
        }
        
      },
      // 获取客户类型下拉数据
      async getCustomerTypeList(){
        try {
          const res = await getCustomerTypeList()
          let response = res.data
          console.log('获取客户类型',response)
          if(response.state){
            let obj = response.data
            let arr = []
            for(var key in obj){
              let item ={
                name:obj[key],
                value:key
              }
              arr.push(item)
            }
            console.log('数据类型',arr)
            this.typeList = arr
          } else{
            this.$message.error(response.message)
          }
        } catch (error) {
          this.$message.error(error.message)
        }
        
      },
      // 获取客户状态下拉数据
      async getCodeList(){
        try {
          const res = await getCodeList({cateId:14})
          let response = res.data
          console.log('获取客户状态',response)
          if(response.state){
            // let obj = response.data
            this.statusList = response.data
          } else{
            this.$message.error(response.message)
          }
        } catch (error) {
          this.$message.error(error.message)
        }
        
      },
      // 加载更多层级
      async loadNextLevel(row,treeNode,resolve){
        console.log('加载更多层级',row)
        try {
          const res = await getListCustomer({customerId:row.customerId})
          let response = res.data
          if(response.state){
            let dataList = response.data.children
            dataList.forEach(element => {
              element.id = element.customerId
              element.parentCustomerId =  response.data.customerId
              if(element.hasChildren === false) {
                delete element['hasChildren']
                delete element['children']
              }
            })
            resolve(dataList)
          }else{
            this.$message.error(response.message)
          }
        } catch (error) {
          this.$message.error(error.message)
        }
        
      },
      // 更新表格数据
      updateData(){
        console.log('更新数据',this.editData)
        if(this.editData.parentCustomerId){
          this.reLoadLazyData(this.editData.parentCustomerId)
        } else {
          this.getData()
        }
      },
      // 编辑或者删除操作成功后，重新获取当前懒加载的数据
      async reLoadLazyData(customerId){
        try {
          const res = await getListCustomer({customerId:customerId})
          let response = res.data
          if(response.state){
            let dataList = response.data.children
            dataList.forEach(element => {
              element.id = element.customerId
              element.parentCustomerId =  response.data.customerId
              if(element.hasChildren === false) {
                delete element['hasChildren']
                delete element['children']
              }
            })
            this.$refs.treeTable.store.states.lazyTreeNodeMap[customerId] = dataList
          }else{
            this.$message.error(response.message)
          }
        } catch (error) {
          this.$message.error(error.message)
        }
        
      },
      // 添加数据
      handleAdd(){
        console.log('点击添加')
        this.editData={}
        this.$refs.createCustomer.open()
      },
      // 时间选择change
      handleDateChange(val){
        console.log('时间选择---',val)
        let searchParams ={
          ...this.searchParams
        }
        if(val){
          searchParams.startDate = val[0];
          searchParams.endDate = val[1];
        } else {
          searchParams.startDate = '';
          searchParams.endDate =  '';
        }
        this.searchParams = searchParams
        this.getData()
      },
      // 编辑数据
      handleEdit(row){
        console.log('编辑数据',row)
        this.editData =row
        this.getCustomer()
        // this.$refs.createCustomer.open()
      },
      // 获取角色详情 
      async getCustomer(){
        let params ={
          customerId:this.editData.customerId
        }
        try {
          const res = await getCustomer(params)
          let response = res.data
          if(response.state){
            let editData ={
              ...this.editData,
              ...response.data
            }
            this.editData = editData
            this.$refs.createCustomer.open()
          } else{
            this.$message.error(response.message)
          }
        } catch (error) {
          this.$message.error(error.message)
        }
        
      },
      // 删除数据
      handleDelete(row){
        console.log('删除数据',row)
        this.deleteData = row
        this.$refs.deleteConfirmDialog.open()
        // this.dialogVisible = true
        // this.customerDelete()
      },
      // api---删除客户
      async customerDelete(){
        let {customerId} = this.deleteData
        let params = {
          customerId
        }
        try {
          const res = await customerDelete(params)
          let response = res.data
          console.log('获取客户列表',response)
          if(response.state){
            this.$message({
              message: this.$t('commonMessage.DeleteSucess'),
              type:'success',
            });
            this.$refs.deleteConfirmDialog.close()
            // this.dialogVisible = false
            // this.$refs.treeTable.store.states.lazyTreeNodeMap = {}
            if(this.deleteData.parentCustomerId){
              this.reLoadLazyData(this.deleteData.parentCustomerId)
            } else {
              this.getData()
            }
            
          } else{
            this.$refs.deleteConfirmDialog.close()
            this.$message.error(response.message)
          }
        } catch (error) {
          this.$message.error(error.message)
        }
        
      },
      // 全部选择
      handleSelectAll(selection) {
        console.log('全部选择', selection)
      },
      // 点击行
      handleRowSelect(row){
        console.log('点击了表格行数据',row)
      },
      handleSelectionChange(selection) {
      },
      // 获取选中的数量
      selection() {
        return this.$refs.treeTable && this.$refs.treeTable.selection.length
      },
      cellClassName(cell){
        const { columnIndex,  row, rowIndex} = cell
        return columnIndex == 0 && !row.layoutStrategyId  ? 'hideClass' : ''
      }
    }
  }
</script>
<style lang="scss" scoped>
  .systemManage-customer{
    // ::v-deep .el-table--border .el-table__row .cell:first-child{
    //   position: relative;
    //   padding-left: 24px;
    // }
    // ::v-deep  .el-table__expand-icon{
    //   position: absolute;
    //   left:4px;
    //   top: 2px;
    //   z-index: 2;
    // }

  }
  
</style>