import router from "../axios";
import config from "../../config/index"
const { baseUrl } = config;

function getTimeConfig(data) {
  //获取时间配置
  return router({
    url: baseUrl+"timeConfig/v1/get",
    data: data,
    method: "POST",
  });
}
function saveTimeConfig(data) {
  // 保存时间配置
  return router({
    url: baseUrl+"timeConfig/v1/save",
    data: data,
    method: "POST",
  });
}

function updateTimeConfig(data) {
  // 更新时间配置
  return router({
    url: baseUrl+"timeConfig/v1/update",
    data: data,
    method: "POST",
  });
}

function getAllTagType(data) {
  // 获取价签类型
  return router({
    url: baseUrl+"tag/v1/getAllTagType",
    data: data,
    method: "POST",
  });
}


function saveTagtype(data) {
  // 保存价签类型
  return router({
    url: baseUrl+"merchant/tag/type/v1/create",
    data: data,
    method: "POST",
  });
}

function getMerchant(data) {
  // 获取商户列表
  
  return router({
    url: baseUrl+"customer/v1/getMerchantList",
    data: data,
    method: "POST",
  });
}

function getRuleList(data) {
  // 获取规则列表
  return router({
    url: baseUrl+"rule/v1/getList",
    data: data,
    method: "POST",
  });
}

// 获取左侧菜单列表
function getScenarioSimplifyList(data) {
  return router({
    url: baseUrl+"scenario/v1/getScenarioSimplifyList",
    data:data,
    method: "POST",
  });
}

// 规则创建保存接口
function ruleCreate(data) {
  return router({
    url: baseUrl+"rule/v1/create",
    data:data,
    method: "POST",
  });
}

// 规则编辑保存接口
function ruleUpdate(data) {
  return router({
    url: baseUrl+"rule/v1/update",
    data:data,
    method: "POST",
  });
}


//规则详情接口
function ruleDetail(data) {
  return router({
    url: baseUrl+"rule/v1/get",
    data:data,
    method: "POST",
  });
}

//规则批量删除接口
function batchDelete(data) {
  return router({
    url: baseUrl+"rule/v1/batchDelete",
    data:data,
    method: "POST",
  });
}
// 规则编辑保存接口
export { getTimeConfig,
         saveTimeConfig, 
         updateTimeConfig, 
         getAllTagType, 
         saveTagtype, 
         getMerchant,
         getRuleList,
         getScenarioSimplifyList,
         ruleCreate,
         ruleUpdate,
         ruleDetail,
         batchDelete
        }